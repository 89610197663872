import React, { Component } from 'react';
import Error from './Error';
import { getPostByParent } from '../Service';

class Announcements extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { parentId } = this.props;
    getPostByParent(parentId).then(
      data => this.setState({ loading: false, data }),
      error => this.setState({ loading: false, error })
    );
  }

  render() {
    const { loading, error, data } = this.state;
    if (loading) return <div className="announcements" />;
    if (error) return <Error error={error} />;

    return (
      <section className="announcements">
        {data.map(({ content }, index) => {
          return (
            <div
              key={index}
              className="mdl-grid"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          );
        })}
      </section>
    );
  }
}

export default Announcements;
