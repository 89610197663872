import React, { Component } from 'react';
import CalendarComponent from '../components/Calendar';
import Error from '../components/Error';
import Spinner from '../components/Spinner';
import { getCalendar } from '../Service';

class Calendar extends Component {
  state = {
    loading: true,
    selectedYear: new Date().getFullYear(),
  };

  componentDidMount() {
    getCalendar().then(
      data => this.setState({ loading: false, data }),
      error => this.setState({ loading: false, error })
    );
  }

  render() {
    const { loading, error, data, selectedYear } = this.state;
    if (loading) return <Spinner />;
    if (error) return <Error error={error} />;

    const keys = Object.keys(data);
    const isCurrentYear = (year, activeYear) =>
      parseInt(year, 10) === parseInt(activeYear, 10);

    return (
      <section className="page-content">
        <h2>Kalender {selectedYear}</h2>
        <ul className="page-content__years-container">
          {keys.map((year, index) => (
            <li
              key={index}
              className={
                isCurrentYear(year, selectedYear)
                  ? 'page-content__year page-content__year--active'
                  : 'page-content__year'
              }
              onClick={() => this.setState({ selectedYear: year })}
            >
              {year}
            </li>
          ))}
        </ul>
        <div className="scroll-container">
          {keys.map((year, index) => (
            <div
              key={index}
              className={isCurrentYear(year, selectedYear) ? '' : 'hidden'}
            >
              <CalendarComponent events={data[year]} />
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Calendar;
