import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Banner from './components/Banner';
import { getBanner } from './Service';

const App = ({ children }) => (
  <>
    <Header />
    <Banner img={getBanner()} />
    {children}
    <Footer />
  </>
);

export default App;
