import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer className="footer">
    <div className="footer-wrapper">
      <div className="footer-wrapper__content mdl-grid">
        <div className="mdl-cell mdl-cell--4-col">
          <h2>Nuttige links</h2>
          <ul>
            <li>
              <a href="http://www.mountainbike.be/">Mountainbike.be</a>
            </li>
            <li>
              <a href="https://www.vwb.be/">Vlaamse Wielerbond</a>
            </li>
            <li>
              <a href="http://cyclocrossviersel.be/">Cyclocross Viersel</a>
            </li>
          </ul>
        </div>
        <div className="mdl-cell mdl-cell--4-col">
          <h2>Sponsors</h2>
          <ul>
            <li>
              <a href="http://www.mampay.be/">Plantencentrum Mampay</a>
            </li>
          </ul>
        </div>
        <div className="mdl-cell mdl-cell--4-col">
          <h2>Contact</h2>
          e-mail: paul@wtczachtjesaan.be
        </div>
      </div>
    </div>
    <div className="footer-bar">
      <div className="footer-bar__content">WTC Zachtjes Aan</div>
    </div>
  </footer>
);

export default Footer;
