import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

const Card = ({ id, slug, title, excerpt, featuredImage }) => {
  const coverStyle = {
    backgroundImage: 'url(' + featuredImage + ')',
  };

  return (
    <Link
      data-card-id={id}
      to={slug}
      className="mdl-cell mdl-cell--4-col card card--shadow"
    >
      <div className="card__cover" style={coverStyle} />
      <h2 className="card__title">{title}</h2>
      <div
        className="card__content"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
    </Link>
  );
};

export default Card;
