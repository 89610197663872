import React from 'react';
import { NavLink } from 'react-router-dom';
import IconBurgerMenu from '../icons/BurgerMenu.svg';
import IconLogo from '../icons/Logo';
import './Header.css';

const Header = () => (
  <header className="header">
    <div className="header__content">
      <IconLogo />
      <div className="menu">
        <input type="checkbox" name="menuToggle" className="menu__toggle" />
        <label htmlFor="menu_toggle" className="menu__toggle-label">
          <img src={IconBurgerMenu} alt={'Burger menu'} />
        </label>
        <nav className="menu__nav">
          <ul className="menu__nav-item-container">
            <li className="menu__nav-item">
              <NavLink
                to="/clubinfo"
                className="menu__nav-item"
                activeClassName="menu__nav-item menu__nav-item--active"
              >
                Clubinfo
              </NavLink>
            </li>
            <li className="menu__nav-item">
              <NavLink
                to="/reglement"
                className="menu__nav-item"
                activeClassName="menu__nav-item menu__nav-item--active"
              >
                Reglement
              </NavLink>
            </li>
            <li className="menu__nav-item">
              <NavLink
                to="/kalender"
                className="menu__nav-item"
                activeClassName="menu__nav-item menu__nav-item--active"
              >
                Kalender
              </NavLink>
            </li>
            <li className="menu__nav-item">
              <a
                href="https://www.facebook.com/pg/WTC-Zachtjes-Aan-2016035398628206/photos/?ref=page_internal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Fotos
              </a>
            </li>
            <li className="menu__nav-item">
              <a href="/forum" target="_self">
                Forum
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
);

export default Header;
