const config = {
  baseEndpoint: 'https://wtczachtjesaan.be/api/wp-json',
  wpEndpoint: '/wp/v2',
  siteEndpoint: '/zachtjesaan/v1',
  banners: [
    'https://wtczachtjesaan.be/api/wp-content/uploads/2018/03/011-e1520709649812.jpg',
    'https://wtczachtjesaan.be/api/wp-content/uploads/2018/03/033.jpg',
    'https://wtczachtjesaan.be/api/wp-content/uploads/2018/03/034.jpg',
    'https://wtczachtjesaan.be/api/wp-content/uploads/2018/03/038.jpg',
    'https://wtczachtjesaan.be/api/wp-content/uploads/2018/03/053-e1520709719716.jpg',
  ],
};

export const wpEndpoint = service => {
  return config.baseEndpoint + config.wpEndpoint + service;
};

export const siteEndpoint = service => {
  return config.baseEndpoint + config.siteEndpoint + service;
};

export const banners = config.banners;

export default {
  wpEndpoint,
  siteEndpoint,
  banners,
};
