import React from 'react';

const Error = ({ error }) => (
  <section className="page-content">
    <h2 className="page-content__title">Error: Iets ging verkeerd!</h2>
    <div>
      <code>{JSON.stringify(error)}</code>
    </div>
  </section>
);

export default Error;
