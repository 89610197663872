import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import Error from '../components/Error';
import { getPage } from '../Service';
import './Page.css';

class Page extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { id } = this.props;
    getPage(id).then(
      data => this.setState({ loading: false, data }),
      error => this.setState({ loading: false, error })
    );
  }

  render() {
    const { loading, data, error } = this.state;
    if (loading) return <Spinner />;
    if (error) return <Error error={error} />;

    const { title, content } = data;
    return (
      <section className="page-content">
        <h2 className="page-content__title">{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </section>
    );
  }
}

export default Page;
