import React from 'react';

const NotFound = () => (
  <section className="page-content">
    <h2>Ooops!</h2>
    <p>Pagina niet gevonden.</p>
  </section>
);

export default NotFound;
