import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import Error from '../components/Error';
import { getPost } from '../Service';
import './Post.css';

class Post extends Component {
  state = {
    loading: true,
  };

  componentWillMount() {
    const { params } = this.props.match;
    getPost(params.id).then(
      data => this.setState({ loading: false, data }),
      error => this.setState({ loading: false, error })
    );
  }

  render() {
    const { loading, error, data } = this.state;
    if (loading) return <Spinner />;
    if (error) return <Error error={error} />;

    const { title, content, featuredImage } = data;
    return (
      <section className="page-content">
        <h2 className="page-content__title">{title}</h2>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <h3>Bijlage</h3>
        <img src={featuredImage} alt={title} />
      </section>
    );
  }
}

export default Post;
