import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Page from './pages/Page';
import Post from './pages/Post';
import Calendar from './pages/Calendar';

const history = createBrowserHistory();

history.listen(location => {
  window.ga('send', 'pageview', location.pathname);
});

const Root = () => (
  <Router history={history}>
    <App>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/page/:page" component={Page} />
        <Route path="/post/:id/:slug" component={Post} />
        <Route path="/clubinfo" render={() => <Page id="763" />} />
        <Route path="/reglement" render={() => <Page id="824" />} />
        <Route path="/kalender" component={Calendar} />
        <Route path="*" component={NotFound} />
      </Switch>
    </App>
  </Router>
);

export default Root;
