import React from 'react';
import './Banner.css';

const Banner = ({ img }) => (
  <div className="banner" style={{ backgroundImage: 'url(' + img + ')' }}>
    <h1 className="banner__title">WTC Zachtjes Aan</h1>
  </div>
);

export default Banner;
