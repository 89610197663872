import React, { Component } from 'react';

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.sort = this.sort.bind(this);
    this.isAscending = true;
    this.state = {
      events: props.events.map(item => {
        item.startDate = new Date(item.startDate);

        return item;
      }),
    };
  }

  sort() {
    let sortedEvents = this.state.events.sort((a, b) => {
      if (this.isAscending) return b.startDate - a.startDate;

      return a.startDate - b.startDate;
    });

    this.isAscending = !this.isAscending;

    this.setState({
      events: sortedEvents,
    });
  }

  render() {
    const formatDate = date => {
      if (date instanceof Date && isNaN(date.getTime())) return;

      return date.toLocaleString('nl-BE', {
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    };
    const isExpired = date => {
      return date.getTime() < Date.now();
    };

    return (
      <table className="table table--hover table-calendar">
        <thead>
          <tr>
            <th onClick={this.sort} className="clickable">
              Datum &#8645;
            </th>
            <th>Plaats</th>
            <th>Km</th>
            <th>Uur</th>
            <th>Wegkapitein</th>
            <th>Hoogtemeters</th>
          </tr>
        </thead>
        <tbody>
          {this.state.events.map((item, index) => {
            return (
              <tr
                key={index}
                className={
                  isExpired(item.startDate)
                    ? 'calendar-row calendar-row--expired'
                    : 'calendar-row'
                }
              >
                <td>{formatDate(item.startDate)}</td>
                <td>{item.place}</td>
                <td>{item.km}</td>
                <td>{item.startTime}</td>
                <td>{item.captain}</td>
                <td>{item.altimeters}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default Calendar;
