import React, { Component } from 'react';
import Spinner from '../components/Spinner';
import Card from '../components/Card';
import Announcements from '../components/Announcements';
import { getPaginatedPosts } from '../Service';

class Home extends Component {
  state = {
    maxPages: 0,
    items: [],
  };

  constructor(props) {
    super(props);
    this.loadPage = this.loadPage.bind(this);

    this.page = props.match.params.page || 1;

    getPaginatedPosts(this.page).then(d =>
      this.setState({
        maxPages: d.maxPages,
        items: d.data,
      })
    );
  }

  loadPage(evt, page) {
    evt.preventDefault();

    getPaginatedPosts(page).then(d =>
      this.setState({
        maxPages: d.maxPages,
        items: this.state.items.concat(d.data),
      })
    );
  }

  render() {
    this.nextPage = this.page++;

    const { maxPages, items } = this.state;
    if (items.length === 0)
      return (
        <section className="post-tiles post-tiles--loading">
          <Spinner />
        </section>
      );

    const generateUrl = nextPage => (
      <a
        href={`/page/${nextPage}`}
        className="wtc-button"
        onClick={evt => this.loadPage(evt, nextPage)}
      >
        Meer posts
      </a>
    );

    return (
      <section className="post-tiles">
        <Announcements parentId="2605" />
        <section className="latestNews mdl-grid">
          {items.map((item, index) => {
            item.slug = `/post/${item.id}/${item.slug}`;

            return <Card key={index} {...item} />;
          })}

          {this.page < maxPages ? generateUrl(this.nextPage) : ''}
        </section>
        <Announcements parentId="2632" />
      </section>
    );
  }
}

export default Home;
