import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo.svg';

export default () => {
  return (
    <div className="logo">
      <Link to="/" className="logo__icon" title="Go to home page">
        <img src={Logo} alt="" />
      </Link>
    </div>
  );
};
