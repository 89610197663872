import cfg from './Config';

const validateResponse = response => {
  if (response.code) return Promise.reject(response);

  return Promise.resolve(response);
};
const getJson = url =>
  fetch(url)
    .then(r => r.json())
    .then(validateResponse);

const mapPost = data => {
  return {
    id: data.id,
    slug: data.slug,
    title: data.title.rendered,
    content: data.content.rendered,
    excerpt: data.excerpt.rendered,
    featuredImage: data.better_featured_image.source_url,
  };
};

const mapPage = ({ id, title, content }) => {
  return {
    id: id,
    title: title ? title.rendered : null,
    content: content ? content.rendered : null,
  };
};

const paginatedResponse = url =>
  fetch(url).then(resp => {
    return resp.json().then(data => {
      return {
        maxPages: resp.headers.get('X-WP-TotalPages'),
        data: data.map(item => mapPost(item)),
      };
    });
  });

export const getPost = id =>
  getJson(cfg.wpEndpoint(`/posts/${id}`)).then(mapPost);
export const getPostByParent = parentId =>
  getJson(cfg.wpEndpoint(`/pages?parent=${parentId}`)).then(data =>
    data.map(mapPage)
  );
export const getPage = id =>
  getJson(cfg.wpEndpoint(`/pages/${id}`)).then(mapPage);
export const getPaginatedPosts = (page, perPage = 3) =>
  paginatedResponse(cfg.wpEndpoint(`/posts?page=${page}&per_page=${perPage}`));

export const getCalendar = () => getJson(cfg.siteEndpoint('/calendar'));

export const getBanner = () =>
  cfg.banners[(Math.random() * cfg.banners.length) | 0];
